import React, { FC, ReactElement, useState } from 'react'
import GenericPage, { GenericPageProps } from '@templates/Generic/Page'
import { Box, Grid, Typography } from '@mui/material'
import { Surface } from '@components'
import { Alert } from '@mui/material'
import useApi, { wasSuccess } from '@hooks/useApi'
import { navigate } from 'gatsby'
import { Button } from 'gatsby-material-ui-components'
import { TUseForm } from '@utils/hooks/useForm'

type TGenericFormPageProps = GenericPageProps & {
  form: TUseForm<any>
  awaitingData?: boolean
  asideContent?: ReactElement
  formActions?: ReactElement
  completeStepActions?: (
    setComplete: React.Dispatch<React.SetStateAction<boolean>>
  ) => ReactElement
  successMessage?: string
  api: ReturnType<typeof useApi>
  autoComplete?: string
  returnPath?: string
}

const GenericFormPage: FC<TGenericFormPageProps> = ({
  form,
  asideContent,
  children,
  autoComplete,
  returnPath,
  api,
  formActions,
  completeStepActions,
  awaitingData,
  successMessage,
  ...pageProps
}) => {
  const [complete, setComplete] = useState<boolean>(false)
  const { loading, makeRequest, error, enqueueSnackbar } = api

  const handleSubmit = (values: typeof form.values) => {
    console.log('values', values)

    makeRequest(values).then((response) => {
      if (!wasSuccess(response)) return false

      if (returnPath) {
        setTimeout(() => {
          navigate(returnPath)
          successMessage &&
            enqueueSnackbar(successMessage, { variant: 'success' })
        }, 100)
      } else {
        setComplete(true)
      }
    })
  }

  return (
    <GenericPage maxWidth="md" {...pageProps}>
      {error && (
        <Box mb={3}>
          <Alert title={`${error.title}`} severity="error">
            {error.detail}
          </Alert>
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item md={asideContent ? 8 : 12}>
          {complete ? (
            <Surface>
              <Box p={3}>
                <Typography>
                  {successMessage || 'Submission Successful'}
                </Typography>
              </Box>
              <Box p={2}>
                {completeStepActions ? (
                  completeStepActions(setComplete)
                ) : (
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        data-cy="formGoBack"
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          window !== undefined && window.history.back()
                        }
                      >
                        {'Go Back'}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="large"
                        onClick={() => {
                          form.reset()
                          setComplete(false)
                        }}
                      >
                        Add Another
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Surface>
          ) : (
            <form
              onSubmit={form.onSubmit((values) => handleSubmit(values))}
              autoComplete={autoComplete}
              noValidate
            >
              <Surface
                sx={
                  awaitingData ? { opacity: 0.4, pointerEvents: 'none' } : null
                }
              >
                <Box p={3}>{children}</Box>
              </Surface>
              <Box pt={4}>
                {formActions || (
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                      >
                        {'Submit'}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="large"
                        variant="text"
                        onClick={() =>
                          window !== undefined && window.history.back()
                        }
                      >
                        {'Cancel'}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </form>
          )}
        </Grid>

        {asideContent && (
          <Grid item md={4}>
            {asideContent}
          </Grid>
        )}
      </Grid>
    </GenericPage>
  )
}

export default GenericFormPage
