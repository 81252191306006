import { TextField, TextFieldPassword } from '@components'
import Select from '@components/Select'
import TextFieldAutocomplete from '@components/TextFieldAutocomplete'
import useApi from '@utils/hooks/useApi'
import useForm, { ValidationRule } from '@utils/hooks/useForm'
import { getUsers, TCreateAlertsEmail, UsersResponse } from '@utils/api'
import { getUserData } from '@utils/auth'
import React, { ReactElement } from 'react'
import { Stack } from '@mui/material'

export type TFormAddAlertEmail = TCreateAlertsEmail

interface Props {
  form: ReturnType<typeof useForm>
}

export const initialValues: TFormAddAlertEmail = {
  name: '',
  email_sender: '',
  email_recipients: [],
  mail_server: '',
  mail_username: '',
  mail_password: '',
  transport_method: 'tls',
  mail_port: 587
}

export const initialValidation: ValidationRule<TFormAddAlertEmail> = {
  name: (value) => value.length > 0 && /^[ a-zA-Z0-9_\-]{1,255}$/.test(value),
  email_sender: (value) => /^\S+@\S+$/.test(value),
  email_recipients: (value) =>
    value.length > 0 && value.every((item) => /^\S+@\S+$/.test(item)),
  mail_port: (value) => typeof value === 'number' && value > 0 && value < 65536
}

export function FormAddAlertEmail({ form }: Props): ReactElement {
  const usersApi = useApi<UsersResponse>({
    apiMethod: getUsers
  })

  const currentUser = getUserData()

  const options = usersApi.response
    ? usersApi.response.users
        .map(({ email }) => email)
        .filter((email) => !!email)
    : [currentUser.email]

  return (
    <Stack spacing={3}>
      <TextField
        required
        id="name"
        form={form}
        helperText="Must be unique, no longer then 255 characters and only contain numbers, letters, spaces, hyphens and underscores."
      />
      <TextField
        required
        id="email_sender"
        label="Sender Email"
        form={form}
        helperText="Must be a valid email"
      />

      <TextFieldAutocomplete
        required
        id="email_recipients"
        label="Recipients Emails"
        form={form}
        options={options}
        freeSolo={true}
        helperText="Select user emails, or manually enter and press return. Must be a valid email. "
      />

      <TextField required id="mail_server" label="Mail Server" form={form} />

      <TextField
        inputProps={{
          autoComplete: 'mail-server',
          'aria-autocomplete': 'none'
        }}
        id="mail_username"
        label="Username"
        form={form}
      />

      <TextFieldPassword
        inputProps={{
          autoComplete: 'mail-server-password',
          'aria-autocomplete': 'none'
        }}
        id="mail_password"
        label="Password"
        form={form}
      />

      <Select
        id="transport_method"
        label="Transport Method"
        form={form}
        options={[
          { value: 'tls', label: 'TLS' },
          { value: 'unencrypted', label: 'Unencrypted' }
        ]}
        onChange={(event) => {
          let value = event.target
            .value as TCreateAlertsEmail['transport_method']

          if (value === 'tls') {
            form.setFieldValue('mail_port', 587)
          } else if (value === 'unencrypted') {
            form.setFieldValue('mail_port', 25)
          }
          form.setFieldValue('transport_method', value)
        }}
      />

      <TextField
        required
        type="number"
        helperText="Port values must only be between 1-65535"
        id="mail_port"
        label="Mail Port"
        form={form}
      />
    </Stack>
  )
}
