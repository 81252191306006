import React from 'react'
import useForm from '@hooks/useForm'
import GenericFormPage from '@templates/Generic/Form'
import { ApiResponse, createAlertsEmail } from '@utils/api'
import { Grid } from '@mui/material'
import useApi from '@hooks/useApi'
import {
  initialValues,
  initialValidation,
  TFormAddAlertEmail,
  FormAddAlertEmail
} from '@components/Form/FormAddAlertEmail'
import { Button } from '@components'

const SettingsAlertsAddEmail = () => {
  const form = useForm<TFormAddAlertEmail>({
    initialValues,
    initialValidationRules: initialValidation
  })

  const api = useApi<ApiResponse<string>>({
    apiMethod: createAlertsEmail,
    requestOnMount: false
  })

  return (
    <GenericFormPage
      title="Add Email Alert"
      breadcrumbs={[
        {
          title: 'Settings',
          to: '/settings/'
        },
        {
          title: 'Notifications',
          to: '/settings/notifications/'
        },
        {
          title: 'Add Email Notification'
        }
      ]}
      form={form as any}
      api={api}
      feature="email_notifications"
      successMessage="Email alert successfully added."
      returnPath="/settings/notifications/"
      formActions={
        <Grid container spacing={2}>
          <Grid item>
            <Button
              size="large"
              feature="email_notifications"
              variant="contained"
              color="primary"
              type="submit"
              disabled={api.loading}
            >
              Add Email Notification
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="text"
              onClick={() => window !== undefined && window.history.back()}
            >
              {'back'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <FormAddAlertEmail form={form as any} />
    </GenericFormPage>
  )
}

export default SettingsAlertsAddEmail
